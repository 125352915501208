<template>
  <div class="p-4 txt-grey-900 fs-14 fw-500">
    <div class="font-bold fs-24 fw-700 mb-3">Hồ sơ sức khoẻ tổng quát</div>
    <div class="flex text-sm font-semibold mb-6">
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([1]) }"
        @click="setActive(1)"
      >Danh sách hồ sơ</div>
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([3]) }"
        @click="setActive(3)"
      >Danh sách chờ</div>
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([2]) }"
        @click="setActive(2)"
      >Thống kê</div>
    </div>
    <div class="bg-white rounded p-6 h-auto overflow-auto mt-2">
      <div
        class="d-flex mb-3"
        :class="checkActive([1]) ? 'justify-content-between' : 'justify-center'"
      >
        <div v-if="checkActive([2,3])" class="text-secondary uppercase fs-30">Coming Soon</div>
        <div v-if="checkActive([1])" class="flex space-x-5">
          <div class="flex items-center border rounded-md border-grey-300 p-2 mr-2 w-60">
            <IconSearch class="cursor-pointer" />
            <input
              v-model="valueSearch"
              class="border-0 txt-grey-500 ml-2 focus:outline-none"
              placeholder="Tên, SĐT, CCCD, BHYT"
            />
          </div>
        </div>
        <div v-if="checkActive([1])" class="flex">
          <ButtonHoDo
            @click="showModalAddBenhAn(true)"
            title="Thêm Hồ sơ"
            class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
          />
        </div>
      </div>
      <div v-if="(checkActive([1]))">
        <el-table
          :data="listHSSK"
          style="width: 100%"
          row-class-name="text-black text-sm cursor-pointer"
          header-cell-class-name="text-black text-base font-semibold"
          @row-click="goToDetail"
        >
          <el-table-column prop="record_name" label="Tên Hồ sơ"></el-table-column>
          <el-table-column prop="patient_name" label="Tên bệnh nhân"></el-table-column>
          <el-table-column prop="patient_phone" label="Số điện thoại"></el-table-column>
          <el-table-column prop="patient_address" label="Địa chỉ"></el-table-column>
        </el-table>
        <AppPagination
          :paginationProps="{ currentPage: current_page, totalItems, pageSize: limit }"
          @onChangePagination="(data) => handleChangePagination(data)"
        />
        <!-- <Pagination v-show="requests && requests.count" :items="requests" :current_page="current_page"
        @onRefresh="getHSSK"></Pagination>-->
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ButtonHoDo } from '@/components/Button'
import { IconSearch } from '@/components/Icon'
import { AppPagination } from '@/components/Common'

const MOCK_DATA = [
  {
    id: 1,
    patient_name: 'Nguyễn Minh Quang',
    patient_phone: '0971395430',
    patient_address: 'Xóm 5, Tiên La, Đoan Hùng, Hưng Hà, Thái Bình',
    record_name: 'Hồ sơ sức khoẻ tổng quát của Quang'
  }
]

export default {
  name: 'HealthRecordOverview',
  components: {
    ButtonHoDo,
    IconSearch,
    AppPagination
  },
  data () {
    return {
      valueSearch: '',
      active: 1,
      listHSSK: [...MOCK_DATA],
      current_page: 1,
      limit: 15,
      totalItems: 0
    }
  },
  mounted () {
    this.handleSelected()
  },
  watch: {
    '$route.query.tab' () {
      this.handleSelected()
    }
  },
  methods: {
    handleSelected () {
      if (this.$route.query.tab) {
        if (this.$route.query.tab === 'general') {
          this.active = 2
        } else if (this.$route.query.tab === 'waiting') {
          this.active = 3
        } else {
          this.active = 1
        }
      } else {
        this.active = 1
        this.$router.replace({ query: { tab: 'list' } })
      }

      // Page
      if (this.$route.query.page) {
        this.current_page = Number(this.$route.query.page)
      }
    },
    checkActive (index) {
      return index.includes(this.active)
    },
    setActive (index) {
      if (index === this.active) return
      if (index === 1) {
        this.$router.replace({ query: { tab: 'list' } })
      } else if (index === 3) {
        this.$router.replace({ query: { tab: 'waiting' } })
      } else {
        this.$router.replace({ query: { tab: 'general' } })
      }
      this.limit = 15
      this.current_page = 1
      this.totalItems = 0
    },
    goToDetail (item) {
      if (!item.id) {
        alert('HSSK không tồn tại!')
        return
      }
      this.$router
        .push({ name: 'HealthRecordOverviewDetail', params: { id: item.id } })
        .catch(e => {})
    },
    showModalAddBenhAn (show) {
      alert('Coming Soon')
      //   window.$('#modal__add_benhan').modal(show ? 'show' : 'hide')
    },

    async getHSSK (data) {
      // TODO: Update when have api
    },

    handleChangePagination (data) {
      if (data.currentPage) {
        this.$router.replace({
          query: {
            tab: this.$route.query.tab,
            page: data.currentPage
          }
        })
      }

      if (this.checkActive([1])) {
        this.getHSSK(data)
      } else {
        this.handleGetHSSKWaiting(data)
      }
    }
  }
}
</script>
  
  <style scoped>
::placeholder {
  color: #98a2b3;
}

.border-bottom-2 {
  border-bottom: 2px solid #20419b !important;
}

.custom:hover {
  background-color: #e4e7ec;
}

.txt-red {
  color: #ff0e0e;
}

.bg-demo {
  background-color: #f6e6ad;
}

.border-custom {
  border: 1px solid #e4e7ec;
}
</style>
  